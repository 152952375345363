@import "colors";

.url-section {
  position: relative;

  Label {
    background-color: $primary-color;
    color: #fff;
    padding: 0 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
  }

  Input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 2px solid $primary-color;
  }

  Button {
    margin-top: 20px;
    background-color: $primary-color;
    min-width: 100px;
  }
}

.preview-section {

  .thumbnail-section {
    margin-bottom: 20px;
    position: relative;

    Label {
      background-color: $primary-color;
      color: #fff;
      padding: 0 20px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin: 0;
      width: 100%;
    }

    img {
      border: 2px solid $primary-color;
      border-radius: 0 0 6px 6px;
      padding-bottom: 35px;
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    Button {
      position: absolute;
      margin: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 40px;
      border-radius: 0 0 6px 6px;
    }
  }

  .btn-fetch {
    background-color: $primary-color;
    border: none;
  }

  .tag-container {
    display: flex;
    flex-wrap: wrap;

    .tag-card {
      background-color: $green-color;
      display: block;
      color: #fff;
      border-radius: 6px;
      padding: 1px 10px;
      margin: 5px;
    }
  }

  .tag-preview {
    margin-bottom: 20px;
  }

  .btn-clipboard {
    margin-left: 20px;
    background-color: $orange-color;
    border: none;
  }

  .lbl-no-tags {
    text-align: center;
    width: 100%;
    font-weight: bold;
  }
}

.sc-ugtqr7-0 .sc-ugtqr7-2 {
  min-height: 700px !important;
}

.sc-beqWaB {
  min-width: 100% !important;
}

.text-editor {

  .ql-container {

    .ql-editor {
      min-height: 600px;
      max-height: 600px;
      overflow-y: scroll;
    }
  }
}

.height-500 {
  height: 500px;
}

.height-200 {
  height: 200px;
}

.height-250 {
  height: 250px;
}

.height-400 {
  height: 400px;
}

.btn-clipboard {
  background-color: $orange-color !important;
  border: none !important;
}

@media all and (max-width: 454px) {
  .preview-section {
    button {
      display: block !important;
    }

    .btn-clipboard {
      margin-top: 10px;
      margin-left: 0 !important;
    }
  }
}

.border-card {
  margin-top: 20px;
  border: 1px solid $light-grey;
  border-radius: 6px;
  padding: 10px;

  h6 {
    padding: 5px 10px;
  }

  .data-row {
    border-top: 1px solid $light-grey;
    margin: 0 5px;

    .data-section {
      height: 65px;

      .slider-picker {
        margin: 8px 0;
      }

      Input {
        margin: 12px 0;
      }

      svg {
        margin: 18px;
        color: $orange-color;
      }
    }
  }
}

.mic-container {
  border-radius: 6px;
  width: 100%;
}

.mic-btn-container {
  Button {
    width: 100%;
    min-height: 50px;
    margin: 5px 0;
  }
}

.color-container {
  border-radius: 6px;
  border: 1px solid $light-grey;
  padding: 10px;
}

.social-card {
  display: flex;
  margin-bottom: 10px;

  img {
    padding: 5px;
    width: 40px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .icn-facebook {
    background-color: #1673E8;
  }

  Input {
    border-radius: 0 !important;
  }

  Input:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .txt-facebook {
    border: 1px solid #1673E8;
  }

  .txt-facebook:focus {
    border: 1px solid #1673E8;
  }

  .icn-twitter {
    background-color: #03A9F4;
  }

  .txt-twitter {
    border: 1px solid #039BE5;
  }

  .txt-twitter:focus {
    border: 1px solid #039BE5;
  }

  .icn-linkedin {
    background-color: #0078D4;
  }

  .txt-linkedin {
    border: 1px solid #0078D4;
  }

  .txt-linkedin:focus {
    border: 1px solid #0078D4;
  }

  .icn-whatsapp {
    background-color: #40C351;
  }

  .txt-whatsapp {
    border: 1px solid #40C351;
  }

  .txt-whatsapp:focus {
    border: 1px solid #40C351;
  }

  .icn-telegram {
    background-color: #00AEF0;
  }

  .txt-telegram {
    border: 1px solid #00AEF0;
  }

  .txt-telegram:focus {
    border: 1px solid #00AEF0;
  }

  .icn-email {
    background-color: #6C19FF;
  }

  .txt-email {
    border: 1px solid #6C19FF;
  }

  .txt-email:focus {
    border: 1px solid #6C19FF;
  }

  .icn-clipboard {
    background-color: #F25206;
    width: 40px;
    padding: 5px;
    border-radius: 0px 6px 6px 0px !important;
    cursor: pointer;

    svg {
      color: #fff;
    }
  }
}

.border {
  border: 1px solid gray;
}