.navbar-container {
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 99;

  .container {
    display: flex;
    justify-content: space-between;

    .img-logo {
      width: 200px;
      padding: 10px 0;
    }

    Button {
      margin: 10px 0;
    }
  }
}