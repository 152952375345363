.banner-container {
  background-image: url('../../assets/img/top-banner-bg.jpg');
  width: 100%;
  margin: 0;
  min-height: 300px;
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;

  .lbl-header {
    font-size: 50px;
    font-weight: bold;
  }

  .lbl-desc {
    max-width: 800px;
    font-size: 20px;
  }
}

@media all and (max-width: 576px) {
  .banner-container {
    text-align: center;
    min-height: 200px;

    .lbl-header {
      font-size: 30px !important;
    }

    .lbl-desc {
      font-size: 15px;
    }
  }
}