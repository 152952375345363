.category-container {
  .itm-badge {
    padding: 8px 20px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .bg-primary {
    background-color: #2A98F5 !important;
    transition: all 0.4s;
  }

  .bg-secondary {
    color: #000;
    background-color: #efefef !important;
    transition: all 0.4s;
  }

  .bg-secondary:hover {
    background-color: #bdbdbd !important;
  }
}
