.footer-container {
  background-color: #313131;
  margin-top: 30px;

  .img-footer {
    margin: 40px 0 20px 0;
    width: 80%;
    max-width: 200px;
  }

  .lbl-desc {
    color: #fff;
    max-width: 80%;
    padding: 0 20px 20px 20px;
  }

  .footer-nav {
    color: #fff;

    a {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      padding: 10px;
      transition: all 0.3s;
    }

    a:hover {
      font-weight: bold;
    }
  }

  .footer-bottom {
    border-top: 1px solid #93939336;
    margin-top: 20px;

    .container {

      p {
        color: #fff;
        margin: 10px 0;

        a {
          text-decoration: none;
          color: #fff;
          font-weight: bold;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .credits {
    margin: 0 !important;
  }

  .footer-bottom {
    padding: 10px 0;
  }
}

.cookie-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.66);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(4px);

  Button {
    margin: 0 20px;
  }
}