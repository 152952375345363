.meme-container {
  border: 5px solid black;
  width: 100%;
  position: relative;

  .img-meme {
    width: 100%;
    object-fit: cover;
  }
}

.overlay-menu {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;

  p {
    margin: 5px 0;
  }
}