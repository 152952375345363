@import "./src/assets/styles/colors";

.card-container {
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 10px;

  .img-container {
    position: relative;
    border: 1px solid #D8D6DE;
    border-radius: 10px;
    padding: 8px;
    margin-right: 10px;
    width: fit-content;

    img {
      max-width: 30px;
      min-width: 30px;
    }
  }

  .lbl-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin: 0;
    display: block;
    cursor: pointer;
  }

  label {
    font-weight: 300;
  }

  .lbl-desc {
    font-weight: 300;
    color: #94a3b8;
  }
}

//.card-container:hover {
//  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.30);
//}

.url {
  text-decoration: none;
  color: #000
}

.icn-new {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 25px !important;
}