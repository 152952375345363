.loader-container {
  position: relative;
  display: none;

  .loader {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.36);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}