.path-container {
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  padding: 10px 15px;

  .path-last {
    font-weight: 500;
    padding: 2px 0;
  }

  a {
    text-decoration: none;
    color: #000;
    padding: 2px 5px;
  }

  a:hover {
    font-weight: 500;
  }
}

.share-section {
  background-color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  padding: 15px;

  button {
    margin: 10px
  }
}