body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F8F9FA !important;
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
    padding: 10px 20px 20px 20px;
    background-color: #fff;
    border-radius: 6px;
}

.btn-primary {
    background-color: #2A98F5 !important;
}

.btn-success {
    background-color: #1D7E7E !important;
}

.br-md-1 {
    border-right: 1px solid rgb(230, 230, 230);
}

.bb-1 {
    border-bottom: 1px solid rgb(230, 230, 230);
}

.mr-md-1 {
    margin-right: 1rem;
}

.Toastify {
    z-index: 99999;
}

@media all and (max-width: 768px) {
    .br-md-1 {
        border: none;
    }

    .youtube-video {
        height: 300px;
    }
}

@media all and (max-width: 500px) {
    .youtube-video {
        height: 150px;
    }
}

.youtube-video {
    width: 100%;
    height: 500px;
}